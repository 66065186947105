import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainMarketingBanner from "../sections/main-marketing-banner"
import OurTeamHomeBanner from "../sections/our-team/our-team-home-banner"
import OurMottoBanner from "../sections/our-team/our-motto-banner"
import OurMissionBanner from "../sections/our-team/our-mission-banner"

import { useIntl } from "gatsby-plugin-react-intl"

const OurTeamPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={data.strapiOurTeam.seo.title}
        description={data.strapiOurTeam.seo.description}
        keywords={data.strapiOurTeam.seo.keywords}
      />
      <OurTeamHomeBanner
        cms={data.strapiOurTeam.homeBanner}
      ></OurTeamHomeBanner>
      <OurMottoBanner cms={data.strapiOurTeam.ourMottoBanner}></OurMottoBanner>
      <OurMissionBanner
        cms={data.strapiOurTeam.ourMissionBanner}
      ></OurMissionBanner>
      <MainMarketingBanner
        cms={data.strapiMain.marketingBanner}
      ></MainMarketingBanner>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiOurTeam {
      seo {
        title
        description
        keywords {
          tag
        }
      }
      homeBanner {
        title
        content
      }
      ourMottoBanner {
        title
        content
      }
      ourMissionBanner {
        title
        content
      }
    }
    strapiMain {
      marketingBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
  }
`

export default OurTeamPage

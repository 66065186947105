import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Markdown from "react-markdown"

const OurMottoBanner = ({ cms }) => {
  return (
    <Box
      className="our-motto-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container>
        <Grid container justify="space-between">
          <Grid item className="banner-left" xs={12} md={5}>
            <Box display="flex" alignItems="center">
              <h2>{cms.title}</h2>
            </Box>
          </Grid>

          <Grid item className="banner-right" xs={12} md={5}>
            <Box display="flex" alignItems="center">
              <div>
                <Markdown allowDangerousHtml>{cms.content}</Markdown>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default OurMottoBanner

import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Markdown from "react-markdown"

const OurMissionBanner = ({ cms }) => {
  return (
    <Box
      className="our-mission-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container>
        <div className="banner-left">
          <h1>{cms.title}</h1>
          <Markdown allowDangerousHtml>{cms.content}</Markdown>
        </div>
      </Container>
    </Box>
  )
}

export default OurMissionBanner
